import {
  container,
  mrAuto,
  mlAuto,
  description,
  title,
} from "assets/jss/material-kit-pro-react.js";
import {colors} from "../../../../../colorTheme";

const descriptionStyle = {
  title,
  container,
  textCenter: {
    textAlign: "center",
  },
  aboutDescription: {
    padding: "60px 0 0 0",
  },
  mrAuto,
  mlAuto,
  description: {
    ...description,
    fontSize: "18px",
    fontWeight: "400",
    color: colors.darkGray,
  }
};

export default descriptionStyle;
