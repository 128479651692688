import {
  mrAuto,
  mlAuto,
  title,
  description,
  cardTitle,
  grayColor,
} from "assets/jss/material-kit-pro-react.js";
import {colors} from "../../../../../colorTheme";

const teamStyle = {
  mrAuto,
  mlAuto,
  title,
  description: {
    ...description,
    marginBottom: "60px",
    fontSize: "18px",
    fontWeight: "400",
    color: colors.darkGray,
  },
  firstLine: {
    ...description,
    fontWeight: "500",
    marginBottom: "20px",
  },
  middleDescription: {
    ...description,
    marginBottom: "20px",
    fontSize: "18px",
    fontWeight: "400",
    color: colors.darkGray,
  },
  // missionDescription: {
  //   ...description,
  //   marginBottom: "60px",
  // },
  cardTitle,
  cardDescription: {
    color: grayColor[0],
  },
  team: {
    paddingBottom: "0px",
  },
  mission: {
    paddingTop: "60px",
    paddingBottom: "0px",
  },
  textCenter: {
    textAlign: "center!important",
  },
  img: {
    width: "100%",
    height: "auto",
  },
  textMuted: {
    color: grayColor[7] + " !important",
  },
  justifyContent: {
    justifyContent: "center!important",
  },
};

export default teamStyle;
