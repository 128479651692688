import React from "react";

// nodejs library that concatenates classes
import classNames from "classnames";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// jss style sheet
import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.js";

const useStyles = makeStyles(descriptionStyle);

export default function SectionDescription() {
  const classes = useStyles();

  return (
    <div className={classNames(classes.aboutDescription, classes.textCenter)}>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <h2 className={classes.title}>Sobre Nosotros:</h2>
          <h5 className={classes.description}>
            Capacitaciones Gas Eficaz es una empresa dedicada a prestar 
            servicios de capacitación con el fin de lograr una mejora continua 
            en programas y contenidos que favorezcan sus operaciones, 
            logrando rebasar las expectativas y satisfacción de los mismos. 
          </h5>
        </GridItem>
      </GridContainer>
    </div>
  );
}
