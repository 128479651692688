import React from "react";

// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import servicesStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/servicesStyle.js";
const useStyles = makeStyles(servicesStyle);

export default function SectionServices() {
  const classes = useStyles();

  return (
    <div className={classes.services}>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(
            classes.mlAuto,
            classes.mrAuto,
            classes.textCenter
          )}
        >
          <h2 className={classes.title}>Why Us</h2>
          <h5 className={classes.description}>
            Propel is a unique product that not only thinks about the insights
            required to decipher deep intelligence and solve business issues,
            but prioritizes, the approach to accelerate generating those
            insights. We understand that our clients sit within competitive
            landscapes; therefore, go-to-market speed is a requirement when
            bringing new insights and ideas.
          </h5>
        </GridItem>
      </GridContainer>
    </div>
  );
}
