import React, { useEffect } from "react";

//react meta tags
import MetaTags from "react-meta-tags";

// nodejs library that loops text
import TextLoop from "react-text-loop";

// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import GridItem from "components/Grid/GridItem.js";
import { FooterGlobal } from "components/Footer/FooterGlobal";

// sections on this page
import SectionDescription from "views/HomePage/Sections/SectionDescription.js";
import SectionOverview from "views/HomePage/Sections/SectionOverview.js";
import { SectionMap } from "./Sections/SectionMap";
import { SectionMaintenance } from "./Sections/SectionMaintenance";

// jss style sheet
import GElogo from "assets/img/GE.png";
import { main, mainRaised } from "assets/jss/material-kit-pro-react.js";
import {
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

// taglines for TextLoop
const taglines = [
  "Cursos de capacitación",
  "Planes de contingencia",
  "Asesorías de manejo de Gas L.P.",
];

export default function HomePage() {
  const theme = useTheme();
  const mediaQuery = useMediaQuery(theme.breakpoints.down("xs"));

  const newUseStyles = makeStyles({
    main: {
      ...main,
    },
    mainRaised: {
      ...mainRaised,
    },
    parallax: {
      height: "100vh",
      overflow: "hidden",
    },
    brand: {
      color: "white",
      display: "flex",
      flexDirection: 'column',
      justifyContent: 'center'
    },
    upperTitle: {
      fontSize: 30,
      fontWeight: 500,
      textTransform: "uppercase",
      [theme.breakpoints.down("xs")]: {
        fontSize: 18,
      },
    },
    title: {
      textAlign: "center",
      fontSize: "5rem",
      fontWeight: "600",
      lineHeight: 0.75,
      [theme.breakpoints.down("xs")]: {
        fontSize: "3rem",
        fontWeight: "600",
        lineHeight: 0.78,
      },
    },
    subTitle: {
      fontSize: 22,
      lineHeight: 0.6,
      [theme.breakpoints.down("xs")]: {
        fontSize: 15,
        lineHeight: 0,
      },
    },
    descriptions: {
      lineHeight: 1.2,
      marginTop: 40,
      fontSize: "40px",
      fontWeight: "300",
      textAlign: "center",
      [theme.breakpoints.down("xs")]: {
        fontSize: "22px",
        marginTop: 10,
      },
    },
    iconLogo: {
      width: "110px",
      height: "200px",
      [theme.breakpoints.down("xs")]: {
        width: "80px",
        height: "150px",
      },
    },
    logoPadding: {
      marginTop: 9,
      [theme.breakpoints.down("xs")]: {
        marginTop: 15,
      },
    },
  });

  const newClasses = newUseStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return () => {};
  });

  return (
    <div style={{ backgroundColor: "black" }}>
      {/* META TAGS */}
      <MetaTags>
        <title>
          Cursos de Capacitación de Gas L.P. | Capacitaciones Gas Eficaz
        </title>
        <meta
          name="description"
          content="Cursos y Certificaciones de Gas L.P. | Todos nuestros cursos cuentan registo ante la STPS"
        />
        <meta
          name="keywords"
          content="gas, capacitacion, manejo de gas, cursos de capacitacion"
        />
      </MetaTags>
      {/* ALL HEADER RELATED (LINKS FOR DESKTOP, DRAWER FOR MOBILE) */}
      <Header
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: mediaQuery ? 160 : 250,
          color: "headerColor",
        }}
      />
      {/* BEGGINING OF CODE */}
      <Parallax
        filter="dark"
        className={newClasses.parallax}
      >
        <Grid
          container
          direction="column"
          justify="center"
          alignContent="center"
          alignItems="center"
        >
          <GridItem className={newClasses.brand}>
            {/* CAPACITACIONES GAS EFICAZ LOGO AND NAME */}
            <Grid container direction="row" justify="center">
              <Grid item>
                <img className={newClasses.iconLogo} src={GElogo} />
              </Grid>
              <Grid item className={newClasses.logoPadding}>
                <Grid
                  container
                  id="Capacitaciones"
                  direction="column"
                  justify="center"
                  alignContent="center"
                  alignItems="center"
                  style={{ padding: "0px 0px 0px 12px" }}
                >
                  <h1>
                    <Typography className={newClasses.upperTitle}>
                      Capacitaciones
                    </Typography>
                    <Typography className={newClasses.title}>
                      Gas Eficaz
                    </Typography>
                  </h1>
                </Grid>
                <Typography
                  id="La nueva forma de capacitar"
                  className={newClasses.subTitle}
                  align="right"
                >
                  La nueva forma de capacitar
                </Typography>
              </Grid>
            </Grid>
            <br />
            <Grid
              container
              direction="column"
              justify="center"
              alignContent="center"
              alignItems="center"
            >
              <div className={newClasses.descriptions}>
                Los mejores servicios en:&nbsp;
                <TextLoop>
                  {taglines.map((item, key) => (
                    <span key={key} style={{ textAlign: "center" }}>
                      <strong>
                        <em>{item}</em>
                      </strong>
                    </span>
                  ))}
                </TextLoop>
              </div>
            </Grid>
          </GridItem>
        </Grid>
      </Parallax>
      <div className={classNames(newClasses.main, newClasses.mainRaised)}>
        {/* <SectionMaintenance/> */}
        <SectionDescription />
        {/* <SectionOverview /> */}
        {/* <Divider style={{padding: 1}}/>
        <SectionMap/>  */}
      </div>
      <FooterGlobal />
    </div>
  );
}
