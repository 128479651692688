export const colors = {
  // primary: "#c9daf5", light blue
  primary: "white", 
  blueGray: "#ccdce3", //blue gray
  // lightBlueGray: "#ccdce3",
  darkBlueGray: "#437faa",
  navyBlue: "#274472",
  bluePurpleGray: "#9aa9d9",
  darkPurpleGray: "#677cbf",
  darkerBlueGray: "#c3d2e8",
  lightGrey: "#ededed",
  darkGray: "#5e5e5e",
  lightBluePurpleGray: "#d7ddf7",
  lightestGray: "#f7f7f7"
};
