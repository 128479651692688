import React from "react";

// nodejs libraries
import CountUp from "react-countup";
import VisibilitySensor from 'react-visibility-sensor';
import PropTypes from 'prop-types';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridItem from "components/Grid/GridItem.js";

// jss style sheet
import counterStyle from "assets/jss/material-kit-pro-react/components/counterStyle.js";

const useStyles = makeStyles(counterStyle);

export default function Counter(props) {
  const classes = useStyles();

  return (
    <GridItem className={classes.fadeBox}>
    {
      props.numArray.map((item) => (
       <VisibilitySensor key={item[2]} partialVisibility>
        {
          ({ isVisible }) => (
            <GridItem className={classes.divBox}>
              {
                isVisible ?
                <GridItem className={classes.numBox}>
                  <CountUp 
                    key={item} 
                    start={0}
                    end={item[0]} 
                    redraw={true}
                    duration={2}/>
                  <div>
                    {item[1]}
                  </div>
                </GridItem>
                : null 
              }
              <span className={classes.variables}>{item[2]}</span>
            </GridItem>
          )
      }
    </VisibilitySensor>
    ))
  }
</GridItem>
  )
}

Counter.propTypes = {
  numArray: PropTypes.array,
};