import {
  container,
  section,
  grayColor,
  title,
} from "assets/jss/material-kit-pro-react.js";
import { colors } from "../../../../../colorTheme.js";

const descriptionStyle = (theme) => ({
  container,
  description: {
    //color: grayColor[0],
    //:"black",
    color: colors.darkGray,
    display: "inline-block",
    // maxWidth: "900px"
    fontWeight: '400',
  },
  section: {
    ...section,
    padding: "70px 0px 0px",
    "& h4$description": {
      fontSize: "1.5em",
    },
    [theme.breakpoints.down('xs')]: {
      "& h4$description": {
        fontSize: "1.2em",
      },
    },
  },
  features: {
    textAlign: "center !important",
    paddingTop: "30px",
    paddingBottom: "0px",
    "& p": {
      fontSize: "16px",
      lineHeight: "1.6em",
    },
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
    textAlign: "center !important",
  },
  button: {
    color: grayColor[0],
    float: "right",
    minHeight: "32px",
    fontSize: "18px",
    fontWeight: "100",
    padding: "12px",
    border: "solid 1px transparent",
    borderRadius: "3px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#E0E0E0",
    },
  },
  div: {
    textAlign: "center",
  },
});

export default descriptionStyle;
