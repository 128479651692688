import React, { useState, useEffect } from "react";

// nodejs libraries
import Marquee from "react-fast-marquee";
import { HashLink } from "react-router-hash-link";
import Carousel from "react-slick";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import { Button } from "@material-ui/core";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Sunburst from "components/Sunburst/Sunburst"; //This should be a components

// imgs
import S3 from "assets/img/connectors/amazons3.png";
import BigQ from "assets/img/connectors/bigquery.png";
import Tera from "assets/img/connectors/teradata.png";
import SalesF from "assets/img/connectors/salesforce.png";
import GA from "assets/img/connectors/googleAnalytics.png";
import MD from "assets/img/connectors/microsoft.png";
import Oracle from "assets/img/connectors/oracle.png";
import SAP from "assets/img/connectors/sap.jpg";
import AA from "assets/img/connectors/adobe.png";

// style
import { colors } from "../../../colorTheme.js";
import featuresStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/featuresStyle.js";

import "assets/css/material-kit-pro-react.css";

const useStyles = makeStyles(featuresStyle);
const useStylesDropdown = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 160,
    maxWidth: 300,
    display: "flex",
    wrap: "nowrap",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 280,
    },
  },
};

const connectOptions = [
  {
    name: "AWS S3",
    icon: S3,
  },
  {
    name: "Big Query",
    icon: BigQ,
  },
  {
    name: "Teradata",
    icon: Tera,
  },
  {
    name: "Google Analytics",
    icon: GA,
  },
  {
    name: "Salesforce",
    icon: SalesF,
  },
  {
    name: "Microsoft Dynamics",
    icon: MD,
  },
  {
    name: "SAP Success Factors",
    icon: SAP,
  },
  {
    name: "Adobe Analytics",
    icon: AA,
  },
  {
    name: "Oracle",
    icon: Oracle,
  },
];

const industries = [
  "Telco",
  "Utilities",
  "Hospitality",
  "Health & Public Service",
];


const industryAndUseCase = [
  {
    name: "Telco",
    useCases: [
      "Avoidable Contact Prevention",
      "Truck Roll Reduction",
      "Churn Reduction",
      "Customer Lifetime Value",
      "Segmentation",
      "Agent Assist",
      "Segmentation",
      "Product Recommendation",
    ]
  },
  {
    name: "Utilities",
    useCases: [
      "Avoidable Contact Prevention",
      "Energy Advisor",
    ]
  },
  {
    name: "Hospitality",
    useCases: [
      "Avoidable Contact Prevention",
      "Document Management",
      "Agent Assist",
    ]
  },
  {
    name: "Health & Public Service",
    useCases: [
      "Avoidable Contact Prevention",
      "Churn Reduction",
      "Segmentation",
      "Agent Assist",
      "Marketing Transformation",
      "Document Management",
    ]
  }
]

const useCaseFeatures = [
  "# Channels: 20+",
  "# Raw Attributes: 1000+",
  "# Pre-codified Derived Signals: 500+",
  "# Pre-defined Models: 100+",
  "# API connections: 25+",
];

const useCaseInfo = [
  {
    name: "Avoidable Contact Prevention",
    definition:
      "Avoidable Contact Prevention is an initiative that shifts customer towards a digital experience and away from live agents to solve their issues. The program takes both a proactive and a reactive mindset when treating the customer.",
    numChannels: "10+", //
    numRawAttributes: "200+", //
    numDerivedSignals: "100+",
    numModels: "30+", //
    numConnections: "10+", //
  },
  {
    name: "Truck Roll Reduction",
    definition:
      "Reduce the amount of technicians sent to residences by proactively solving their issues with agent enhancement in the call centers.",
    numChannels: "7+", //
    numRawAttributes: "100+", //
    numDerivedSignals: "50+",
    numModels: "20+", //
    numConnections: "8+", //
  },
  {
    name: "Energy Advisor",
    definition:
      "Make call center agents energy advisors with intelligent signals used to predict, forecast, and recommend optimal energy usage strategies.",
    numChannels: "5+", //
    numRawAttributes: "100+", //
    numDerivedSignals: "75+",
    numModels: "15+", //
    numConnections: "10+", //
  },
];

export default function DataFoundation({ ...rest }) {
  // stylers
  const classes = useStyles();
  const classesDropdown = useStylesDropdown();

  // keep track of the industry and use cases the user selects
  const [selectedIndustry, setSelectedIndustry] = useState([]);
  const [selectedUseCases, setSelectedUseCases] = useState([]);

  const [availUseCases, setAvailUseCases] = useState([])
  const handleUseCaseChange = (event) => {
    setSelectedUseCases(event.target.value);
  };

  const handleIndustryChange = (event) => {
    setSelectedIndustry(event.target.value);
    /* console.log(event.target.value) */

    for (let i = 0; i < industryAndUseCase.length; i++) {
      if (industryAndUseCase[i].name === event.target.value) {
        setAvailUseCases(industryAndUseCase[i].useCases);
      }
    }

  };




  //Input: use case name, Output: return information about use case if info exists, else return []
  const determineInfo = (useCase) => {
    for (let i = 0; i < useCaseInfo.length; i++) {
      if (useCaseInfo[i].name === useCase) {
        return useCaseInfo[i];
      }
    }
    return [];
  };

  // re-render when selected use cases changes
  useEffect(() => { }, [selectedUseCases]);

  // settings for carousel
  const settings = {
    dots: true,
    dotsClass: "slick-dots",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    arrows: false,
  };

  return (
    <div className="cd-section" {...rest}>
      <div className={classes.container}>
        <div className={classes.features1} style={{ paddingBottom: "0px" }}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={2}
              md={2}
              className={classes.mlAuto + " " + classes.mrAuto}
            >
              <div style={{ padding: "20px", paddingTop: "0" }}>
                <h2
                  className={classes.title}
                  style={{ fontSize: "24px", textAlign: "left" }}
                >
                  Quick Links
                </h2>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    paddingBottom: "10px",
                  }}
                >
                  <HashLink
                    style={{ width: "100%", textAlign: "left" }}
                    className={classes.links}
                    smooth
                    to="/explore#data_mapping"
                  >
                    Data Mapping
                  </HashLink>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    paddingBottom: "10px",
                  }}
                >
                  <HashLink
                    style={{ float: "left", textAlign: "left" }}
                    className={classes.links}
                    smooth
                    to="/explore#connections"
                  >
                    Connections & Relationships
                  </HashLink>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    paddingBottom: "10px",
                  }}
                >
                  <HashLink
                    className={classes.links}
                    style={{ float: "left", textAlign: "left" }}
                    smooth
                    to="/explore#intelligent_foundation"
                  >
                    Omni-Channel Foundation
                  </HashLink>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    paddingBottom: "10px",
                  }}
                >
                  <HashLink
                    className={classes.links}
                    smooth
                    to="/explore#activation"
                  >
                    Activation
                  </HashLink>
                </div>
              </div>
            </GridItem>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={classes.mlAuto + " " + classes.mrAuto}
            >
              <h2 className={classes.title} style={{ fontSize: "42px" }}>
                Data Foundation
              </h2>
              <h3 className={classes.title}>Where It Begins</h3>
              <h5 className={classes.description}>
                We at Propel know that making decisioning intelligence
                ‘available’ can be complex, time intensive.​ A key pattern we
                know is, commonly complex problems are met with commonly complex
                solutions
                <br />
                <br />
                We at Propel know that customer analytical problems can be
                complex and time intensive. But hundreds of customer engagements
                requiring hundreds of thousands of hours taught us that no
                problem is unique.
                <br />
                In the era of advanced analytics,{" "}
                <b>
                  <em>
                    commmonly complex problems are met with commonly complex
                    solutions
                  </em>
                </b>
                .
                <br />
                <br />
                Propel is the evolution of that philosophy harded by our
                customer engagments. We distilled customer data to hierarchical,
                aggregated views of omnichannel customer interations. These
                abstracted data groups are our first foundational capability; a
                key to our <strong>AI powered solutions</strong>.
                <br />
                <br />
                Below is Propel&apos;s complete data foundation. Explore that
                foundation by selecting an Industry and Use Case and observing
                how changes to the question, change the data required.
              </h5>
            </GridItem>
            <GridItem
              xs={12}
              sm={2}
              md={2}
              className={classes.mlAuto + " " + classes.mrAuto}
            ></GridItem>
          </GridContainer>
          {/* <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={classes.mlAuto + " " + classes.mrAuto}
            >
              <h2 className={classes.title}>Quick Links</h2>
              <HashLink className={classes.links} smooth to="/explore#data_mapping">Data Mapping</HashLink>
              <br />
              <HashLink className={classes.links} smooth to="/explore#connections">Connections & Relationships</HashLink>
              <br />
              <HashLink className={classes.links} smooth to="/explore#intelligent_foundation">Intelligent Foundation</HashLink>
              <br />
              <HashLink className={classes.links} smooth to="/explore#activation">Activation</HashLink>
            </GridItem>
          </GridContainer> */}
          <div id="data_mapping" style={{ height: "50px" }}></div>
          <GridContainer>
            <GridItem xs={12}>
              <h2 className={classes.title} style={{ fontSize: "42px" }}>
                Data Mapping
              </h2>
              <h5 className={classes.description}>
                We distilled the complex customer engagement landscape into
                hierarchical, aggregated view of omni-channel customer
                interactions.
              </h5>
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    flex: "0 0 50%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "340px",
                      // backgroundColor: 'red'
                      paddingLeft: '10px'
                    }}
                  >
                    <FormControl className={classes.formControl} style={{ width: "300px", margin: '0' }}>
                      <InputLabel id="demo-simple-select-label">Industry</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedIndustry}
                        onChange={handleIndustryChange}
                      >
                        {
                          industries.map((name, index) => (
                            <MenuItem key={index} value={name}>{name}</MenuItem>
                          ))
                        }
                        
                      </Select>
                    </FormControl>
                    <FormControl className={classesDropdown.formControl}  style={{ width: "300px",margin: '0'  }}>
                      <InputLabel id="demo-mutiple-checkbox-label">
                        Use Case
                      </InputLabel>
                      <Select
                        labelId="demo-mutiple-checkbox-label"
                        id="demo-mutiple-checkbox"
                        multiple
                        value={selectedUseCases}
                        onChange={handleUseCaseChange}
                        input={<Input />}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                      >
                        {availUseCases.map((name, index) => (
                          <MenuItem
                            key={name}
                            value={name}
                            // style={{ width: "300px" }}
                          >
                            <Checkbox
                              checked={selectedUseCases.indexOf(name) > -1}
                              key={index}
                            />
                            <ListItemText primary={name} key={index} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div style={{ flex: "1", overflow: "hidden" }}>
                  <div
                    style={{
                      marginBottom: "20px",
                      marginTop: "10px",
                      width: "450px",
                    }}
                  >
                    {selectedUseCases.length === 0 ? (
                      <div
                        style={{
                          backgroundColor: colors.lightGrey,
                          padding: "10px",
                          borderRadius: "10px",
                        }}
                      >
                        <h6
                          style={{
                            padding: "0",
                            width: "100%",
                            textAlign: "center",
                            margin: "0",
                          }}
                        >
                          Overall Use Case Summary
                        </h6>
                        <ul
                          style={{
                            "list-style-type": "none",
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                            fontSize: "12px",
                          }}
                        >
                          {useCaseFeatures.map((feature, index) => (
                            <li style={{ padding: "0" }} key={index}>
                              {feature}
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      <div
                        style={{
                          backgroundColor: colors.lightGrey,
                          padding: "10px",
                          marginBottom: "10px",
                          borderRadius: "10px",
                        }}
                      >
                        <Carousel {...settings}>
                          {selectedUseCases.map((name, index) => (
                            <div key={index}>
                              <h6
                                style={{
                                  padding: "0",
                                  width: "100%",
                                  textAlign: "center",
                                  margin: "0",
                                }}
                              >
                                {name} Summary
                              </h6>
                              <ul
                                style={{
                                  "list-style-type": "none",
                                  margin: "0",
                                  padding: "0",
                                  textAlign: "left",
                                  fontSize: "12px",
                                }}
                              >
                                <li>
                                  Definition: {determineInfo(name).definition}
                                </li>
                                <li>
                                  # Channels: {determineInfo(name).numChannels}
                                </li>
                                <li>
                                  # Raw Attributes:{" "}
                                  {determineInfo(name).numRawAttributes}
                                </li>
                                <li>
                                  # Pre-codified Derived Signals:{" "}
                                  {determineInfo(name).numDerivedSignals}
                                </li>
                                <li>
                                  # Pre-defined Models:{" "}
                                  {determineInfo(name).numModels}
                                </li>
                                <li>
                                  # API connections:{" "}
                                  {determineInfo(name).numConnections}
                                </li>
                              </ul>
                            </div>
                          ))}
                        </Carousel>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <Sunburst selection={selectedUseCases} industry={selectedIndustry}/>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingRight: "200px",
                }}
              >
                <Button className={classes.button}>Get Started</Button>
              </div>
            </GridItem>
          </GridContainer>
          <div
            className={classes.features1}
            id="connections"
            style={{ marginBottom: "0" }}
          >
            <GridContainer
              style={{
                backgroundColor: colors.lightestGray,
                paddingBottom: "50px",
              }}
            >
              <GridItem
                xs={12}
                sm={8}
                md={8}
                className={classes.mlAuto + " " + classes.mrAuto}
              >
                <h2 className={classes.title} style={{ fontSize: "42px" }}>
                  Connections & Relationships
                </h2>
                <h5
                  className={classes.description}
                  style={{ paddingBottom: "0" }}
                >
                  We understand data can reside in multiple places. Use our
                  homegrown/partner connections to collocate the data. Use your
                  existing platforms or use our no-code environment to define
                  data relationships and schemas.
                </h5>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    paddingBottom: "40px",
                  }}
                >
                  <Button className={classes.button}>Get Started</Button>
                </div>
                <div
                  style={{
                    backgroundColor: "white",
                    padding: "25px",
                    borderRadius: "10px",
                  }}
                >
                  <Marquee speed={55} gradientWidth={50}>
                    {connectOptions.map((item, index) => (
                      <div key={index}>
                        <img
                          src={item.icon}
                          alt={item.name}
                          style={{
                            height: "100px",
                            width: "auto",
                            paddingLeft: "20px",
                          }}
                        />
                      </div>
                    ))}
                  </Marquee>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    </div>
  );
}
