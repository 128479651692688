import React, { useEffect } from "react";

// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import Footer from "components/Footer/Footer.js";

// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";
import SectionDescription from "views/AboutUsPage/Sections/SectionDescription.js";
import SectionTeam from "views/AboutUsPage/Sections/SectionTeam.js";
import SectionServices from "views/AboutUsPage/Sections/SectionServices.js";

// jss style sheet
import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";
import { FooterGlobal } from "components/Footer/FooterGlobal";
import { Helmet } from "react-helmet";
import { MetaTags } from "react-meta-tags";
import { Grid, useMediaQuery, useTheme } from "@material-ui/core";
import SectionMision from "./Sections/SectionMision";
import { SectionVision } from "./Sections/SectionVision";
import { SectionValores } from "./Sections/SectionValores";
import { SectionCursos } from "./Sections/SectionCursos";
import { SectionInstructor } from "./Sections/SectionInstructor";

const useStyles = makeStyles(aboutUsStyle);

export default function AboutUsPage() {
  const theme = useTheme();
  const mediaQuery = useMediaQuery(theme.breakpoints.down("xs"));
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });

  return (
    <div>
      <MetaTags>
        <title>Información sobre nosotros | Capacitaciones Gas Eficaz</title>
        <meta
          name="description"
          content="Somos una empresa enfocada en realizar cursos de capacitacion de Gas L.P. y de otros hidrocarburos para su uso y manejo seguro."
        />
        <meta
          name="keywords"
          content="gas, capacitacion, manejo de gas, cursos de capacitacion"
        />
      </MetaTags>
      <Header
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: mediaQuery ? 50 : 200,
          color: "headerColor",
        }}
      />
      <Parallax filter="dark" small>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem
              md={8}
              sm={8}
              className={classNames(
                classes.mlAuto,
                classes.mrAuto,
                classes.textCenter
              )}
            >
              <h1 className={classes.title}>¿Quiénes Somos?</h1>
              <h4>
                Conoce más sobre nuestros servicios y sobre
                Capacitaciones Gas Eficaz
              </h4>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <SectionDescription />
          <SectionMision />
          <Grid container direction="row" justify="center">
            <Grid item sm={12} md={4}>
              <SectionVision />
            </Grid>
            <Grid item sm={12} md={4}>
              <SectionValores />
            </Grid>
            <Grid item sm={12} md={4}>
              <SectionInstructor />
            </Grid>
          </Grid>
          <Grid container direction="column" justify="center">
            <Grid item>
          <SectionCursos/>
            </Grid>
          </Grid>
          {/* <SectionTeam />
          <SectionServices /> */}
        </div>
      </div>
      <FooterGlobal />
    </div>
  );
}
