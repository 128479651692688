import {
  container,
  mlAuto,
  mrAuto,
  title,
  description,
  blackColor,
  whiteColor,
  hexToRgb,
} from "assets/jss/material-kit-pro-react.js";
import {colors} from "../../../../../colorTheme";

const features = {
  container,
  mlAuto,
  mrAuto,
  title,
  description: {
    ...description,
    fontSize: "18px",
    fontWeight: "400",
    color: colors.darkGray,
  },
  features1: {
    textAlign: "center",
    padding: "80px 0",
  },
  features2: {
    padding: "80px 0",
  },
  features3: {
    padding: "80px 0",
    "& $phoneContainer": {
      maxWidth: "220px",
      margin: "0 auto",
    },
  },
  features4: {
    padding: "80px 0",
    "& $phoneContainer": {
      maxWidth: "260px",
      margin: "60px auto 0",
    },
  },
  features5: {
    padding: "80px 0",
    backgroundSize: "cover",
    backgroundPosition: "50%",
    backgroundRepeat: "no-repeat",
    position: "relative",
    "& $title": {
      marginBottom: "30px",
    },
    "& $title,& $container": {
      position: "relative",
      zIndex: "2",
      color: whiteColor,
    },
    "&:after": {
      background: "rgba(" + hexToRgb(blackColor) + ",0.55)",
      position: "absolute",
      width: "100%",
      height: "100%",
      content: "''",
      zIndex: "0",
      left: "0px",
      top: "0px",
    },
    "& $container": {
      "& $gridContainer:last-child": {
        "& $gridItem": {
          borderBottom: "0",
        },
      },
      "& $gridItem": {
        border: "1px solid rgba(" + hexToRgb(whiteColor) + ", 0.35)",
        borderTop: "0",
        borderLeft: "0",
        "&:last-child": {
          borderRight: "0",
        },
      },
    },
    "& $infoArea5": {
      textAlign: "center",
      maxWidth: "310px",
      minHeight: "320px",
      "& h4,& p,& svg,& .fab,& .fas,& .far,& .fal,& .material-icons": {
        color: whiteColor,
      },
    },
  },
  gridContainer: {},
  gridItem: {},
  textCenter: {
    textAlign: "center",
  },
  phoneContainer: {
    "& img": {
      width: "100%",
    },
  },
  infoArea: {
    maxWidth: "none",
    margin: "0 auto",
    padding: "10px 0 0px",
  },
  infoArea5: {},
  button: {
    position: "relative",
    fontWeight: "400",
    fontSize: "16px",
    textTransform: "capitalize",
    lineHeight: "20px",
    padding: "8px",
    textDecoration: "none",
    margin: "0px",
    display: "inline-flex",
    backgroundColor: colors.lightGrey,
    // backgroundColor: "black",
    borderRadius: "5px solid rgba(204, 220, 227, 0.8)",
    "& $icons": {
      marginRight: "3px",
    },
    // "&:hover": {
    //   backgroundColor: colors.whiteColor,
    //   textColor: colors.blackColor,
    // },
  },
  links: {
    ...description,
    fontSize: '16px',
    fontWeight: "500",
    color: colors.darkGray,
  },
  startButton: {
    padding: '15px 60px 15px 60px',
    border: `1px solid ${colors.lightBluePurpleGray}`,
    backgroundColor: 'transparent',
    color: 'white',
    fontSize: '15px',
    fontWeight: '500',
    marginBottom: '50px',
    boxShadow: 'none',
    transition: '0.4s',
    borderRadius: '7px',
    "&:hover": {
      backgroundColor: colors.lightBluePurpleGray,
      border: `1px solid ${colors.lightBluePurpleGray}`,
      color: 'black',
      boxShadow: 'none',
    },
    "&:focus": {
      backgroundColor: colors.lightBluePurpleGray,
      border: `1px solid ${colors.lightBluePurpleGray}`,
      color: 'black',
      boxShadow: 'none',
    },
  }
};

export default features;
