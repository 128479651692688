import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router";

import "assets/scss/material-kit-pro-react.scss?v=1.10.0";
import "./index.css"

// pages for this product
import AboutUsPage from "views/AboutUsPage/AboutUsPage.js";
import ContactUsPage from "views/ContactUsPage/ContactUsPage.js";
import ErrorPage from "views/ErrorPage/ErrorPage.js";
import HomePage from "views/HomePage/HomePage.js";
import ExplorePage from "views/Explore/Explore.js";
var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/sobre-nosotros" component={AboutUsPage} />
      {/* <Route path="/contact-us" component={ContactUsPage} />
      <Route path="/explore" component={ExplorePage} />
      <Route path="/error-page" component={ErrorPage} /> */}
      <Route path="/" component={HomePage} />
    </Switch>
  </Router>,
  document.getElementById("root")
);
