import React from "react";

// nodejs library that concatenates classes
import classNames from "classnames";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// jss style sheet
import teamStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/teamStyle.js";

const useStyles = makeStyles(teamStyle);

export default function SectionTeam() {
  const classes = useStyles();

  return (
    <div className={classes.team}>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(
            classes.mrAuto,
            classes.mlAuto,
            classes.textCenter
          )}
        >
          <h2 className={classes.title}>Our Partners</h2>
          <h5 className={classes.firstLine}>
            Propel is funded and positioned underneath ACE + Solutions within
            Solutions.AI.
          </h5>
          <h5 className={classes.middleDescription}>
            ACE was tasked by Accenture to create high fidelity omnichannel
            customer journeys by enabling proactive messaging, digitizing
            personal experiences, empowering human interactions. Bo Zhang is our
            ACE + Product lead and has tailored Propel to fulfill the ACE
            objective.
          </h5>
          <h5 className={classes.description}>
            Solutions.AI is a new way of working as One Accenture to put AI at
            the heart of everything we do. Solutions.AI brings together our
            broad sales reach, our deep pool of IP, industry experience and
            differentiated Applied Intelligence know-how under a new global
            operating model for speed and scale. Lan Guan, Solutions.AI Global
            lead, is our Solutions.AI sponsor and has guided our end-to-end
            product strategy to position ourselves within the new Accenture
            Business Unit.
          </h5>
        </GridItem>
      </GridContainer>
    </div>
  );
}
