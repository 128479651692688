import React from "react";

// nodejs library that concatenates classes
import classNames from "classnames";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// jss style sheet
import teamStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/teamStyle.js";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles(teamStyle);

export function SectionInstructor() {
  const classes = useStyles();

  return (
    <div className={classes.team}>
      <Grid container justify="center">
        <GridItem
          md={8}
          sm={8}
          className={classNames(
            classes.mrAuto,
            classes.mlAuto,
            classes.textCenter
          )}
        >
          <h2 className={classes.title} style={{marginBottom:20}}>Contáctanos:</h2>
          <h5 className={classes.middleDescription}>
            {/* <strong>Ramón Tomás Marroquín Garza</strong><br/> */}
            40 años de experiencia en la industria del GAS, L.P.<br/>
            Cel.  <a href="tel:8122001659" style={{color: '#4caf50'}}>8122001659</a><br/>
            Email: <a href="mailto:contacto@gaseficaz.com" style={{color: '#03a9f4'}}>contacto@gaseficaz.com</a><br/>
          </h5>
        </GridItem>
      </Grid>
    </div>
  );
}
