import React from "react";

// nodejs library that concatenates classes
import classNames from "classnames";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// jss style sheet
import teamStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/teamStyle.js";
import { Grid, useTheme } from "@material-ui/core";

const useStyles = makeStyles(teamStyle);


export function SectionCursos() {
  const theme = useTheme();
  const newUseStyles = makeStyles({
    body: {
      marginBottom:0,
      [theme.breakpoints.down("xs")]: {
        marginBottom:45,
      },
    }
  });
  const classes = useStyles();
  const newClasses = newUseStyles();
  const valores = [
    "Plantas de almacenamiento, distribución y comercialización",
    "Estaciones de carburación de uso comercial y auto abasto",
    "Autotanques para la distribución y comercialización",
    "Uso de Gas, L.P. para vehículos y montacargas",
    "Semirremolques para el transporte",
    "Instalaciones de aprovechamiento comercial e industrial de Gas, L.P. y Natural",
    "Supervisión efectiva en plantas de almacenamiento",
    "Operación de extintores y sus características",
    "Montacargas, operación segura",
    "Venta segura y efectiva de Gas, L.P.",
    "Seguridad Industrial",
    "Primeros Auxilios",
  ];

  return (
    <div className={classes.team}>
      <Grid
        container
        direction="column"
        alignContent="center"
        alignItems="center"
        className={newClasses.body}
      >
        <Grid md={8} item>
        <h2 className={classes.title} style={{textAlign: "center"}}>Algunos de nuestros cursos:</h2>
        <h5 className={classes.middleDescription}>
          Manejo seguro y adecuado de Gas, L.P. y Natural para la prevención y atención a siniestros, enfocados a:
        </h5>
        <h5 className={classes.middleDescription}>
          <Grid item>
            <ul>
              {valores.map((valor, index) => {
                return <li key={index}>{valor}</li>;
              })}
            </ul>
          </Grid>
        </h5>
        </Grid>
      
      </Grid>
    </div>
  );
}
