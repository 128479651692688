import DraftsIcon from '@mui/icons-material/Drafts';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';

export const Links = [
    {
        linkTo: '/sobre-nosotros',
        name: '¿Quiénes Somos?',
        icon: PersonSearchIcon
    },
    
]