import React from "react";
import { useHistory } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

// jss style sheet
import featuresStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/featuresStyle.js";
import { colors } from "colorTheme";

const useStyles = makeStyles(featuresStyle);

export default function Activation({ ...rest }) {
  const classes = useStyles();
  const history = useHistory();

  const startClickHandler = () => {
    history.push({
      pathname: '/login-page',
   });
  }
  return (
    <div>
      <div className="cd-section" {...rest} id="activation"
        style={{ backgroundColor: colors.lightestGray }}>
        <div className={classes.container}>
          <div className={classes.features1}>
            <GridContainer>
              <GridItem
                xs={12}
                sm={8}
                md={8}
                className={classes.mlAuto + " " + classes.mrAuto}
              >
                <h2 className={classes.title} style={{ fontSize: '42px', color: 'black' }}>Activation</h2>
                <h3 className={classes.title} style={{ fontSize: '22px', color: 'black' }}>Omni-Channel Cockpit</h3>
                <h3 className={classes.title} style={{ fontSize: '22px', color: 'black' }}>Derived & Decision Signals</h3>
                <h3 className={classes.title} style={{ fontSize: '22px', color: 'black' }}>API Connections</h3>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <div style={{ backgroundColor: 'black' }}>
      <div className={classes.container}>
          <div className={classes.features1}>
            <GridContainer>
              <GridItem
                xs={12}
                sm={8}
                md={8}
                className={classes.mlAuto + " " + classes.mrAuto}
              >
                <h2 className={classes.title} style={{ fontSize: '42px', color: 'white' }}>Get Started with Propel</h2>
                <Button onClick={startClickHandler} className={classes.startButton}>Get Started</Button>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>

    </div>
  );
}
