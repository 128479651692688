import React from "react";

// nodejs library that concatenates classes
import classNames from "classnames";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// jss style sheet
import teamStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/teamStyle.js";

const useStyles = makeStyles(teamStyle);

export function SectionVision() {
  const classes = useStyles();

  return (
    <div className={classes.team}>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(
            classes.mrAuto,
            classes.mlAuto,
            classes.textCenter
          )}
        >
          <h2 className={classes.title}>Nuestra Visión</h2>
          <h5 className={classes.middleDescription}>
            Ser una empresa de capacitación profesional, moderna y confiable. 
            Enfocada en las necesidades de nuestros clientes.
          </h5>
        </GridItem>
      </GridContainer>
    </div>
  );
}
