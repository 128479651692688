import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// jss style sheet
import featuresStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/featuresStyle.js";

const useStyles = makeStyles(featuresStyle);

export default function IntelligentFoundation({ ...rest }) {
  const classes = useStyles();

  return (
    <div className="cd-section" {...rest} id = "intelligent_foundation" style = {{marginTop: '0', paddingTop: '0'}}>
      <div className={classes.container}>
        <div className={classes.features1} style = {{paddingTop: '0', paddingBottom: '50px'}}>
          <GridContainer >
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={classes.mlAuto + " " + classes.mrAuto}
            >
              <h2 className={classes.title} style = {{fontSize: '42px'}}>Omni-Channel Foundation</h2>
              <h5 className={classes.description}>
                Raw data points only take us so far. The Omni-Channel Foundation is exactly what it claims to be 
                - a truly actionable data foundation which is a combination of raw and decisioning signals to deliver business results​.
              </h5>
              <div style={{ display: 'flex', justifyContent: 'flex-end', paddingBottom: "40px" }}><Button className={classes.button}>Get Started</Button></div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
