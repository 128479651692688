import {
  whiteColor,
} from "assets/jss/material-kit-pro-react.js"
const counterStyle = {
  fadeBox: {
    display: "flex", 
    flexDirection: "row", 
    justifyContent: "center",
  },
  numBox: {
    padding: "10px 0", 
    flexDirection:"row",
    display:"flex",
    fontSize: "50px",
    color: whiteColor,
  },
  variables: {
    color: whiteColor,
    fontSize:"30px", 
    marginBottom: "35px",
    fontWeight: 500,
  },
  divBox: {
    width: "auto",
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
    whiteSpace: "nowrap",
    margin: "5px 10px",
  },

}



export default counterStyle;