import React, { useEffect, useState } from "react";

// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "@material-ui/core/Button";

// sections of this Page
import DataFoundation from "./Sections/DataFoundation";
import IntelligentFoundation from "./Sections/InteligentFoundation";
import Activation from "./Sections/Activation";
import Footer from "components/Footer/Footer.js";

// jss style sheet
import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle";


const useStyles = makeStyles(() => ({
  ...aboutUsStyle,
  videoButton: {
    position: "relative",
    fontWeight: "400",
    fontSize: "16px",
    textTransform: "uppercase",
    lineHeight: "20px",
    textDecoration: "none",
    margin: "0px",
    display: "inline-flex",
    color: "white",
    borderColor: "white",
    padding: 10,
    "&:hover": {
      backgroundColor: "white",
      color: "gray",
    },
  },
}));

export default function ExplorePage() {
  const classes = useStyles();

  const [showVideoDialog, setShowVideoDialog] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
   

      <Header
        brand="Propel"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 400,
          color: "headerColor",
        }}
      />

      <Parallax
        filter="dark"
        small
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem
              md={8}
              sm={8}
              className={classNames(
                classes.mlAuto,
                classes.mrAuto,
                classes.textCenter
              )}
            >
              <h1 className={classes.title}>How Propel Works</h1>
              <h4>
                Explore Propel end-to-end to learn about the benefits of our
                ecosystem.
              </h4>
              <Button
                className={classes.videoButton}
                variant="outlined"
                onClick={() => setShowVideoDialog(true)}
              >
                Watch video
              </Button>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classes.main}>
        <DataFoundation id="datamapping" />
        <IntelligentFoundation id="intelligentFoundation" />
        <Activation id="Activation" />
        <Footer
          theme="white"
          content={
            <div>
              <div className={classes.left}>
                <a
                  href="www.accenture.com"
                  target="_blank"
                  className={classes.footerBrand}
                >
                  Propel @ Accenture
                </a>
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
}
