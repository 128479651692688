import { Typography } from '@mui/material'
import React from 'react'
import Footer from './Footer'

export const FooterGlobal = () => {
  return (
    <Footer
        theme="dark"
        content={
            <div>
              <Typography variant='h5' style={{fontSize: 15, color: 'white', textAlign: 'center'}}>
                <strong>Gas Eficaz S.A de C.V.</strong>
              </Typography>
              <Typography variant='h5' style={{fontSize: 15, color: 'white', textAlign: 'center'}}>
                <strong>Monterrey, N.L.</strong>
              </Typography>
            </div>
        }
      />
  )
}
