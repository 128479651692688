import React from "react";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

// @material-ui icons
import AssessmentIcon from "@material-ui/icons/Assessment";
import SettingsInputCompositeIcon from "@material-ui/icons/SettingsInputComposite";
import ListAltIcon from "@material-ui/icons/ListAlt";
import TimelineIcon from "@material-ui/icons/Timeline";
import DescriptionIcon from "@material-ui/icons/Description";
import LibraryAddCheckIcon from "@material-ui/icons/LibraryAddCheck";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/presentationSections/descriptionStyle.js";
import { Divider, useMediaQuery, useTheme } from "@material-ui/core";
import { Grid } from "@mui/material";

const useStyles = makeStyles(descriptionStyle);

export default function SectionDescription() {
  const classes = useStyles();
  const theme = useTheme();
  const mediaQuery = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem md={8} sm={8}>
            <h2 className={classes.title}>Capacitaciones Gas Eficaz</h2>
            <div className={classes.div}>
              <h4
                className={classes.description}
                style={{ textAlign: "justify"}}
              >
                Somos una empresa enfocada en realizar servicios de asesoría y
                capacitación a todo el personal en materia de hidrocarburos: Gas
                L.P., Gasolina, Diesel, entre otros.
                <br></br>
                <br />
                <ul style={{ fontSize: mediaQuery ? 17 : 22 }}>
                  <li>
                    Se dan a conocer las caracteristicas principales que lo
                    componen.
                  </li>
                  <li>
                    El uso y manejo seguro de estos combustibles para la
                    prevención de accidentes.
                  </li>
                  <li>
                    Procedimientos para el trasvase y/o trasiego entre
                    recipientes.
                  </li>
                </ul>
                <br></br>
              </h4>
            </div>
          </GridItem>
        </GridContainer>
        <Divider style={{ padding: 1 }} />
        <Grid
          container
          justifyContent="center"
          alignContent="center"
          alignItems="center"
          style={{ padding: "50px 0px" }}
        >
          <Grid item sm={6} md={4} lg={3}>
            <InfoArea
              vertical
              icon={LibraryAddCheckIcon}
              title="Experiencia y Confiabilidad"
              description="Nuestros instructores cuentan con más de 35 años de experiencia en la industria del Gas L.P."
              iconColor="success"
            />
          </Grid>
          <Grid item sm={6} md={4} lg={3}>
            <InfoArea
              vertical
              icon={DescriptionIcon}
              title="Recomendaciones y Medidas Preventivas"
              description="Ofrecemos apoyo y consulta en las operaciones del Gas, L.P."
              iconColor="info"
            />
          </Grid>
          <Grid item sm={6} md={4} lg={3}>
            <InfoArea
              vertical
              icon={AssessmentIcon}
              title="Autorización y Registro"
              description="Contamos con la aprobación y registro por parte de la Secretaría del Trabajo y Previsión Social."
              iconColor="warning"
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
