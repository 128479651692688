import {
  title,
  description,
  mrAuto,
  mlAuto,
} from "assets/jss/material-kit-pro-react.js";
import {colors} from "../../../../../colorTheme";

const servicesStyle = {
  title,
  mrAuto,
  mlAuto,
  services: {
    // paddingTop: "10px",
    paddingBottom: "80px",
  },
  textCenter: {
    textAlign: "center",
  },
  description: {
    ...description,
    fontSize: "18px",
    fontWeight: "400",
    color: colors.darkGray,
  }
};

export default servicesStyle;
