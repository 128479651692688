import React from "react";

// nodejs library that allows for the Fade transition
import Fade from 'react-reveal/Fade';

// nodejs library that concatenates classes
import classNames from "classnames";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Counter from "components/CountUp/Counter.js";

// @material-ui icons
import SpeedIcon from '@material-ui/icons/Speed';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import SearchIcon from '@material-ui/icons/Search';
import PeopleIcon from '@material-ui/icons/People';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// jss style sheet
import overviewStyle from "assets/jss/material-kit-pro-react/views/presentationSections/overviewStyle.js";
const useStyles = makeStyles(overviewStyle);

// stat numbers 
const acnNumbers = [
  [600, "+", "Data Elements"],
  [300, "+", "Derived Variables"],
  [4, "", "Industries"],
]
const acnNumbers2 = [
  [100, "+", "Customer Engagments"],
  [200, "K+", " Worked Hours"]
]

export default function SectionOverview() {
  
  // for component styles
  const classes = useStyles();

  return (
    <div className={classes.section} >
      <div
        className={classes.features5}
        
      >
        <GridItem md={8} className={classNames(classes.mlAuto, classes.mrAuto)}>
          <h2 className={classes.title}>Imagine...</h2>
        </GridItem>
        <div className={classes.container} >
          <GridContainer justify="center">
            <GridItem sm={3} className={classes.featuresShow}>
              <InfoArea
                title="ACCELERATING THE INTELLIGENCE PROCESS"
                description={
                  <p>
                    Kickstart omni-channel customer analytics with
                    pre-defined element to generate a singular view of customer engagements
                  </p>
                }
                icon={SpeedIcon}
                iconColor="gray"
                vertical={true}
              />
            </GridItem>
            <GridItem sm={3} className={classes.featuresShow}>
              <InfoArea
                title="PROVIDING A SINGLE INTELLIGENT VIEW"
                description={
                  <p>
                    Create one holistic view of the customers to
                    analyze behavior and identify optimal paths
                  </p>
                }
                icon={AllInclusiveIcon}
                iconColor="gray"
                vertical={true}
              />
            </GridItem>
            <GridItem sm={3} className={classes.featuresShow}>
              <InfoArea
                title="ENABLING GENUINE INTERACTIONS"
                description={
                  <p>
                    Break down barriers and provide consistent experiences across channels
                  </p>
                }
                icon={PeopleIcon}
                iconColor="gray"
                vertical={true}
              />
            </GridItem>
            <GridItem sm={3} className={classes.featuresShow}>
              <InfoArea
                title="DECIPHERING DEEP INTELLIGENCE"
                description={
                  <p>
                    Identify the critical data elements for engagement reporting, modelling, and analysis
                  </p>
                }
                icon={SearchIcon}
                iconColor="gray"
                vertical={true}
              />
            </GridItem>
          </GridContainer>
        </div>
        </div>
        <div className={classes.sectionTestimonials}>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem
                xs={15}
              >
                <h2 className={classes.whiteTitle}>By The Numbers</h2>
                <Fade left>
                  <Counter numArray={acnNumbers}/>
                </Fade>
                <Fade right>
                  <Counter numArray={acnNumbers2} />
                </Fade>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
  );
}
