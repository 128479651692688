import { Grid, makeStyles, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import React from "react";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import descriptionStyle from "assets/jss/material-kit-pro-react/views/presentationSections/descriptionStyle.js";

const useStyles = makeStyles(descriptionStyle);
const geoUrl =
  "https://gist.githubusercontent.com/diegovalle/5129746/raw/c1c35e439b1d5e688bca20b79f0e53a1fc12bf9e/mx_tj.json";

export const SectionMap = () => {
    const classes = useStyles();
    const theme = useTheme();
    const mediaQuery = useMediaQuery(theme.breakpoints.down('xs'));
  return (
      <Grid container justify="center" alignContent="center" direction="column">
          <Grid item>
          <h2 className={classes.title}>Hemos impartido nuestros servicios en los siguientes estados:</h2>
          </Grid>
          <ComposableMap
            style={{ backgroundColor: "White", maxWidth: mediaQuery ? 450 : 1300}}
            projection="geoAlbers"
            projectionConfig={{
                center: [-5, 25]
            }}
            >
                <Geographies
                    style={{ backgroundColor: "green", bottom: 100 }}
                    geography={geoUrl}
                >
                    {({ geographies }) =>
                    geographies.map((geo) => (
                        <Geography key={geo.rsmKey} geography={geo} />
                    ))
                    }
                </Geographies>
            </ComposableMap>

      </Grid>
    
  );
};

