import React from "react";

// nodejs library that concatenates classes
import classNames from "classnames";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// jss style sheet
import { Grid } from "@material-ui/core";

import {
  title,
  description,
} from "assets/jss/material-kit-pro-react.js";




export function SectionValores() {
  const newUseStyles = makeStyles({
    title: {
      ...title,
      marginBottom:10
    },
    
    middleDescription: {
      ...description,
      marginBottom: "20px",
      fontSize: "18px",
      fontWeight: "400",
      color: '#5e5e5e', 
    },
  });
  const newClasses = newUseStyles();

  const valores = [
    "Profesionalismo",
    "Responsabilidad",
    "Lealtad",
    "Ética",
    "Orden",
    "Compromiso",
  ];

  return (
      <Grid
        container
        direction="column"
        justify="center"
        alignContent="center"
        alignItems="center"
      >
        <h2 className={newClasses.title}>Nuestros Valores</h2>
        <h5 className={newClasses.middleDescription}>
            <ul style={{ padding: 0, margin: 0 }}>
              {valores.map((valor, index) => {
                return <li key={index}>{valor}</li>;
              })}
            </ul>
        </h5>
      </Grid>
  );
}
